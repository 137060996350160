import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './Admin.css';

export default function UploadImages() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [images, setImages] = useState([]);
    const [image, setImage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in by calling the session check API
        fetch('/backend/check_session.php')
            .then((response) => response.json())
            .then((data) => {
                if (!data.loggedIn) {
                    // If not logged in, redirect to login page
                    navigate('/admin');
                } else {
                    setIsLoggedIn(true);
                    fetchImages(); // Fetch images if logged in
                }
            })
            .catch((error) => console.error('Error checking session:', error));
    }, [navigate]);

    // Fetch existing images from the server
    const fetchImages = async () => {
        try {
            const response = await fetch('/backend/get_images.php');
            const data = await response.json();
            if (data.success) {
                setImages(data.images);
            } else {
                console.error("Failed to fetch images");
            }
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    const handleUpload = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        if (image) formData.append('image', image);

        try {
            const response = await fetch('/backend/upload_image.php', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (data.success) {
                alert("Image uploaded successfully!");
                setImage(null);
                fetchImages(); // Refresh images list
            } else {
                alert(data.message || "Failed to upload image");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            alert("An error occurred. Please try again.");
        }
    };

    const handleDelete = async (imageName) => {
        if (window.confirm("Are you sure you want to delete this image?")) {
            try {
                const response = await fetch('/backend/delete_image.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ image_name: imageName }),
                });
                const data = await response.json();
                if (data.success) {
                    alert("Image deleted successfully!");
                    fetchImages(); // Refresh images list
                } else {
                    alert("Failed to delete image");
                }
            } catch (error) {
                console.error("Error deleting image:", error);
                alert("An error occurred. Please try again.");
            }
        }
    };

    if (!isLoggedIn) {
        return null; // Do not render the component if the user is not logged in
    }

    return (
        <div id="upload-section">
            <h2>Upload and Manage Images</h2>
            <form onSubmit={handleUpload}>
                <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    accept="image/*"
                    required
                />
                <button type="submit">Upload Image</button>
            </form>

            <div id="images-list">
                <h3>Uploaded Images</h3>
                <ul>
                    {images.map((img) => (
                        <li key={img}>
                            <img src={`/backend/gallery/${img}`} alt={img} width="100" />
                            <button onClick={() => handleDelete(img)}>Delete</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
