import React, { useState } from 'react';
import './Main.css';
import './Frame4.css';

export default function Frame4() {
    const [activeCard, setActiveCard] = useState(null);  // State to track the currently active card

    const showCard = (cardId, event) => {
        // Hide all other cards by resetting their z-index and opacity
        const allCards = document.querySelectorAll('.f4b3cards');
        allCards.forEach((card) => {
            card.classList.remove('show');  // Remove the 'show' class to hide all other cards
            card.style.zIndex = 1;  // Set z-index to a lower value for all cards
        });
    
        // Get the clicked card and circle elements
        const cardElement = document.getElementById(`card${cardId}`);
        const circleElement = event.target;
    
        // Get the bounding rectangle of the circle's parent container to ensure relative positioning
        const container = document.getElementById('forcards');
        const containerRect = container.getBoundingClientRect();
    
        // Get the bounding rectangle of the clicked circle
        const circleRect = circleElement.getBoundingClientRect();
    
        // Calculate the position of the circle relative to its parent container
        const circleLeft = circleRect.left - containerRect.left;
        const circleTop = circleRect.top - containerRect.top;
    
        // Get the current screen width for responsive adjustments
        const screenWidth = window.innerWidth;
    
        // Default values for desktop screens
        let cardOffset = 240;  // Default offset for large desktop screens
        let cardWidth = '15vw';
        let cardHeight = '35vh';
        let isVerticalLayout = false;  // Track if layout is vertical
        let topOffset = -90;  // Default top offset to position above the circle
    
        // Font size variables
        let cardFontSize = '17px';
        let cardDescFontSize = '14.5px';
    
        // Apply different conditions based on screen widths for precise positioning and font sizes
        if (screenWidth <= 412) {
            cardOffset = 30;  // Smallest offset for very small mobile screens
            topOffset = -20;  // Adjust top position for smaller screens
            isVerticalLayout = true;  // Vertical layout for very small screens
            cardFontSize = '18px';
            cardDescFontSize = '13px';
            cardWidth = '65vw';
            cardHeight = '15vh';
        } else if (screenWidth <= 610) {
            cardOffset = 40;  // Small offset for small mobile screens
            topOffset = -30;
            isVerticalLayout = true;
            cardFontSize = '18px';
            cardDescFontSize = '13px';
            cardWidth = '65vw';
            cardHeight = '15vh';
        } else if (screenWidth <= 638) {
            cardOffset = 50;
            topOffset = -30;
            isVerticalLayout = true;
            cardFontSize = '18px';
            cardDescFontSize = '13px';
            cardWidth = '65vw';
            cardHeight = '15vh';
        } else if (screenWidth <= 740) {
            cardOffset = 70;  // Adjusted for small tablet screens
            topOffset = -40;
            isVerticalLayout = true;
            cardFontSize = '20px';
            cardDescFontSize = '14px';
            cardWidth = '60vw';
            cardHeight = '15vh';
        } else if (screenWidth <= 925) {
            cardOffset = 100;  // Medium tablet screens
            topOffset = -100;
            isVerticalLayout = false;  // Switch to horizontal layout for larger screens
            cardFontSize = '15px';
            cardDescFontSize = '12px';
            cardWidth = '20vw';
            cardHeight = '25vh';
        } else if (screenWidth <= 1310) {
            cardOffset = 100;  // Smaller desktop screens
            topOffset = -100;
            isVerticalLayout = false;  // Reset to horizontal layout for desktops
            cardFontSize = '18px';
            cardDescFontSize = '14px';
            cardWidth = '20vw';
            cardHeight = '30vh';
        } else {
            cardOffset = 100;  // Full-size desktop screens
            topOffset = -100;
            isVerticalLayout = false;
            cardFontSize = '19px';
            cardDescFontSize = '15px';
            cardWidth = '15vw';
            cardHeight = '35vh';
        }
    
        // Dynamically position the card based on the calculated values
        if (isVerticalLayout) {
            // For vertical layouts, position above the clicked circle and center it
            cardElement.style.top = `${circleTop + topOffset}px`;  // Adjust top position relative to the circle
            cardElement.style.left = `50%`;  // Center the card horizontally
            cardElement.style.transform = `translateX(-50%)`;  // Offset to center the card relative to the circle
        } else {
            // For horizontal layouts, position based on the left and top values
            cardElement.style.left = `${circleLeft - cardOffset}px`;
            cardElement.style.top = `${circleTop + topOffset}px`;
            cardElement.style.transform = 'none';  // Reset the transform property
        }
    
        // Set the font size dynamically for the active card
        cardElement.querySelector('.f4b3cardheads').style.fontSize = cardFontSize;
        cardElement.querySelector('.f4b3carddesc').style.fontSize = cardDescFontSize;
    
        // Adjust the card width and height dynamically
        cardElement.style.width = cardWidth;
        cardElement.style.height = cardHeight;
    
        // Show the clicked card with higher z-index and opacity
        cardElement.style.zIndex = 2;  // Set the z-index higher for the active card
        cardElement.classList.add('show');  // Add the 'show' class to make it visible
    
        // Update the active card state
        setActiveCard(cardId);
    };
    
    
    
    
    
    
    

    return (
        <>
            <div id="frame4">
                <div id="f4b1">
                    <div id="f4b1b1">
                        <div className="boxheads1">See</div>
                        <div className='boxheads2'>What Sets Us Apart</div>
                        <div className="boxheadsdesc">Discover What Makes Our Resort Unique</div>
                    </div>
                    <div id="f4b1b2">
                        <div className="f4b1b2cards" id="total"><div className="cardhead1">Total</div><div className="cardhead2">50 Camps</div></div>
                        <div className="f4b1b2cards" id='f4b1b2cardparts'>
                            <div className="f4b1b2cardparts"><div className="cardhead1">Total Area</div><div className="cardhead2">4000 Sqft</div></div>
                            <div className="f4b1b2cardparts"><div className="cardhead1">Total</div><div className="cardhead2">3000 Visitors</div></div>
                        </div>
                        <div className="f4b1b2cards" id="city2"><div className="cardhead1">Drive from city</div><div className="cardhead2">44 Km</div></div>
                    </div>
                </div>
                <div id="f4b2">
                    <div id="f4b2b1">
                        <div className="boxheads1">Explore</div>
                        <div className='boxheads2'>Camp Itinerary</div>
                    </div>
                    <div id="f4b2b2">
                        <div className="boxheadsdesc">Immerse Yourself in an Unforgettable Journey with Our Carefully Curated Itinerary, Packed with Exciting Activities, Scenic Exploration, and Memorable Experiences Awaiting You Each Day.</div>
                    </div>
                </div>
                <div id="f4b3">
                    <div id="forcards">
                        <div className={`f4b3cards ${activeCard === 1 ? 'show' : ''}`} id='card1'>
                            <div className="f4b3cardheads">Swiss Tent Accommodation</div>
                            <div className="f4b3carddesc">Experience a luxurious stay in our star-rated Swiss tents nestled in the heart of the Thar Desert, complete with all modern amenities.</div>
                        </div>
                        <div className={`f4b3cards ${activeCard === 2 ? 'show' : ''}`} id='card2'>
                            <div className="f4b3cardheads">Gaala Dinner</div>
                            <div className="f4b3carddesc">Savor authentic Rajasthani cuisine at our desert buffet, offering a true taste of tradition.</div>
                        </div>
                        <div className={`f4b3cards ${activeCard === 3 ? 'show' : ''}`} id='card3'>
                            <div className="f4b3cardheads">Cultural Program & DJ Party</div>
                            <div className="f4b3carddesc">Ignite your evening with live Rajasthani folk music and dances, followed by an electrifying DJ party.</div>
                        </div>
                        <div className={`f4b3cards ${activeCard === 4 ? 'show' : ''}`} id='card4'>
                            <div className="f4b3cardheads">Camel Safari</div>
                            <div className="f4b3carddesc">Delight in thrilling activities around Jaisalmer city and embark on unforgettable desert safari tours at Sam Sand Dunes.</div>
                        </div>
                    </div>
                    <div id="forblocks">
                        <div className={`circles ${activeCard === 1 ? 'active-circle' : ''}`} id='circle1' onClick={(event) => showCard(1, event)}></div>
                        <div className={`circles ${activeCard === 2 ? 'active-circle' : ''}`} id='circle2' onClick={(event) => showCard(2, event)}></div>
                        <div className={`circles ${activeCard === 3 ? 'active-circle' : ''}`} id='circle3' onClick={(event) => showCard(3, event)}></div>
                        <div className={`circles ${activeCard === 4 ? 'active-circle' : ''}`} id='circle4' onClick={(event) => showCard(4, event)}></div>
                    </div>
                </div>
            </div>
        </>
    )
}
