import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Main.css';
import './Navbar.css';
import logo from './Images/logo.png';

export default function Navbar() {
    const [navbarBackground, setNavbarBackground] = useState(false);
    const [navVisible, setNavVisible] = useState(false);

    // Toggle navbar visibility
    const toggleNavbar = () => {
        setNavVisible(!navVisible);
    };

    // Handle scrolling to hide the navbar
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setNavVisible(false); // Hide navbar when scrolling
                setNavbarBackground(true);
            } else {
                setNavbarBackground(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Close navbar and scroll to the top when any link is clicked
    const handleLinkClick = () => {
        setNavVisible(false); // Hide navbar when a link is clicked
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling to the top
        });
    };

    return (
        <nav className={`navbar navbar-expand-lg ${navbarBackground ? 'bg-white' : 'bg-transparent'}`} id='navbar'>
            <div className="container-fluid" id='innav'>
                <Link className="navbar-brand" to="#" onClick={handleLinkClick}>
                    <img src={logo} alt="Logo" id='logo'/>
                    <span id="resortName">Desert Pearl Camp & Resort</span>
                </Link>
                {/* Hamburger Button */}
                <button
                    className="navbar-toggler"
                    type="button"
                    id='ham'
                    aria-controls="navbarNav"
                    aria-expanded={navVisible}
                    aria-label="Toggle navigation"
                    onClick={toggleNavbar}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* Navbar Items */}
                <div className={`collapse navbar-collapse ${navVisible ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/" onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/About" onClick={handleLinkClick}>About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/desert" onClick={handleLinkClick}>Desert Safari</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Packages" onClick={handleLinkClick}>Packages</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/gallery" onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/jaisalmer" onClick={handleLinkClick}>Explore Jaisalmer</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Contact" onClick={handleLinkClick}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
