import React, { useState } from 'react';
import './Main.css';
// import './Frame1.css';
import Slide1 from './Images/tours.JPG';
import Slide2 from './Images/premium.JPG';

export default function Frame1() {
    const slidesData = [
        {
            head: "A Full-Day Tour at Our Resort",
            desc: "The tour kicks off with an exhilarating dune bashing adventure, followed by a range of enjoyable activities including camel rides, photo opportunities in traditional Rajasthani attire, and henna tattooing. As evening falls, relax in our desert camp, which mimics a Bedouin atmosphere, or gather around the fire until we serve a delicious BBQ dinner with a variety of salads, starters, and desserts. After a fantastic cultural show, the real highlight of the tour awaits: an overnight safari. We’ll provide you with excellent accommodations for the night in our desert camp, complete with sleeping bags, blankets, and unlimited tea and coffee.",
            img: Slide1
        },
        {
            head: "Premium Amenities at Our Camp",
            desc: "We offer fully equipped tent accommodations with private toilets and electricity, providing a home-away-from-home experience. Immerse yourself in nature and Rajasthani heritage in our elegantly designed tents, complete with a central room and verandah. Enhance your stay with our open-air restaurant and exclusive deluxe camel safaris and camping experiences throughout Rajasthan's desert. Our base camp, located 3 km before Sam Sand Dunes, features our special desert dream package, which includes an overnight stay, camel safari, traditional welcome, refreshments, folk music and dance, a campfire, dinner, and breakfast.",
            img: Slide2
        }
    ];

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const handlenextClick = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slidesData.length);
    };

    const handleprevClick = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex - 1 + slidesData.length) % slidesData.length);
    };

    return (
        <>
            <div id="abframe2">
                <div id="abf2b1">
                    <div className="boxheads1">Experience</div>
                    <div className='boxheads2'>{slidesData[currentSlideIndex].head}</div>
                    <div id="abf2img"><img src={slidesData[currentSlideIndex].img} alt="" id='slideabf' /></div>
                </div>
                <div id="abf2b2">
                    <div className="boxheadsdesc">{slidesData[currentSlideIndex].desc}</div>
                    <div id="forbtnflex">
                        <div id="f2b2sliderbtnprev" className="f2b2sliderbtn2" onClick={handleprevClick}>&larr;</div>
                        <div id="f2b2sliderbtnnext" className="f2b2sliderbtn2" onClick={handlenextClick}>&rarr;</div>
                    </div>
                </div>
            </div>
        </>
    );
}
