import React, { useState } from 'react';
import './Main.css';
import Slide1 from './Images/desertsafari.jpg';
import Slide2 from './Images/ab2.png';
import Slide3 from './Images/villagesafari1.jpg';
import Slide4 from './Images/citytour.jpg';
import Slide5 from './Images/campfire.jpg';
import Slide6 from './Images/gg8.jpg';
import Slide7 from './Images/gg10.jpg';
import Slide8 from './Images/quaddesert.jpg';
import Slide9 from './Images/moter.jpg';

export default function Frame1() {
    const slidesData = [
        {
            main:`Experience the`,
            head: "Thrill of Off-Road Jeep Safari",
            desc: `The jeep safari is a thrilling adventure in the Thar Desert. Whether you choose to enjoy the sunrise or sunset safari, you'll witness breathtaking desert landscapes. Some tours also include a visit to traditional mud houses. Our 4x4 jeep safari offers a 35-minute ride across the dunes.`,
            img: Slide1,
            style: {
                transform:`translate(0vw,-8vh)`
            }
        },
        {
            main:`Uncover the`,
            head: "Desert's Magic on a Camel Safari",
            desc: `One of India’s most memorable and enchanting experiences is riding a camel through the windswept desert and camping under the stars. A camel safari in Jaisalmer offers a unique chance to witness the traditional desert life of India. Explore the vast Great Indian Desert of Rajasthan on camelback, and immerse yourself in the rustic and regal charm of Rajasthan's sandy fields, small dunes, and stunning sunrises and sunsets.`,
            img: Slide2,
                style: {
                    transform:`translate(0vw,-5vh)`
                }
        },
        {
            main:`Explore`,
            head: "Local Culture on a Village Safari",
            desc: `We can arrange a village tour that includes visits to various communities, allowing you to interact with locals as they go about their daily routines. The people of Jaisalmer are renowned for their vibrant festivals, fasting traditions, ceremonies, and their ability to live joyfully despite limited resources. Rajasthan, primarily a rocky and sandy desert, has required its inhabitants to adapt to harsh conditions. With rainfall occurring only once a year and limited underground aquifers, the areas where people can settle are restricted. Some land is so saline that wells in those regions produce undrinkable water.`,
            img: Slide3,
            style: {
                transform:`translate(0vw,-5vh)`
            }
        },
        {
            main:`Discover`,
            head: "Rich History on a Rajasthan City Tour",
            desc: `A Jaisalmer city tour takes you on a timeless journey and a sensory delight. As you explore this captivating city in the heart of the Thar Desert, you'll be enchanted by its rich history and architectural marvels. We offer guided tours to key attractions, including the Army War Museum, Barabagh Village, Lodarwa Jain Temple, Kuldhara Village (a historic site), Jaisalmer Fort, Patwon Ki Haveli, Salim Singh Ki Haveli, Nathmal Ki Haveli, and Gadisar Lake.`,
            img: Slide4,
            style: {
                transform:`translate(0vw,0vh)`
            }
        },
        {
            main:`Come &`,
            head: "Gather Around the Crackling Campfire",
            desc: `In Jaisalmer, our camp hosts evening recreational activities, featuring a bonfire each night. The crackling flames create a warm and inviting ambiance, casting flickering shadows on the golden sand dunes. Guests gather around the enchanting campfire, sharing stories and laughter, and forging connections that bridge cultures and borders.`,
            img: Slide5
        },
        {
            main:`Immerse in`,
            head: "Cultural Delights of Our Camp",
            desc: `We treat you to a delicious BBQ dinner, complete with a delightful assortment of salads, starters, and desserts. Following an incredible cultural show, the real highlight awaits: our overnight safari. Dressed in vibrant traditional attire, local artists display their talents through mesmerizing Rajasthani folk music and dance. The rhythmic beats of the dholak, the enchanting melodies of the sarangi, and the elegant movements of the Kalbeliya dancers combine to create a rich tapestry of cultural expression.`,
            img: Slide6,
            style: {
                transform:`translate(0vw,-5vh)`
            }
        },
        {
            main:`Romance under the stars with`,
            head: "Private Dinner on the Dunes",
            desc: `Immerse yourself in a world of enchantment with our Private Dinner on the Dunes experience. Our expert team will arrange a beautifully decorated table, complete with flickering candles, plush cushions, and elegant details, creating a cozy and intimate setting for just the two of you. With the stars overhead and the desert breeze gently rustling through the dunes, time feels like it stands still.`,
            img: Slide7
        },
        {
            main:`Ignite your`,
            head: "Adventure with Quad Biking",
            desc: `Quad biking in Jaisalmer offers the perfect adrenaline-pumping experience in the Thar Desert. This four-wheel all-terrain vehicle is specifically designed for off-road adventures, making the ATV ride a unique way to explore the Sam Sand Dunes. Enjoy a thrilling 10-minute ride across the dunes, completely off-road, capturing the exhilarating spirit of the Thar Desert. Don't forget to snap some incredible photos to remember this adventurous moment.`,
            img: Slide8,
            style: {
                transform:`translate(0vw,-10vh)`
            }
        },
        {
            main:`Soar to new heights with`,
            head: "Parasailing and Paramotoring",
            desc: `Parasailing in Jaisalmer offers an exhilarating adrenaline rush paired with breathtaking panoramic views of the desert. Another exciting adventure, paramotoring—also known as powered paragliding—allows you to explore even deeper into the Thar Desert. This activity involves a wing and a small motorized backpack engine, providing a unique 10 to 15-minute ride over the Sam Sand Dunes, available between 7 AM and 7 PM.`,
            img: Slide9,
            style: {
                transform:`translate(0vw,-8vh)`
            }
        }
    ];

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const handlenextClick = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slidesData.length);
    };

    const handleprevClick = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex - 1 + slidesData.length) % slidesData.length);
    };

    return (
        <>
            <div id="abframe2">
                <div id="abf2b1">
                    <div className="boxheads1">{slidesData[currentSlideIndex].main}</div>
                    <div className='boxheads2'>{slidesData[currentSlideIndex].head}</div>
                    <div id="abf2img"><img src={slidesData[currentSlideIndex].img} alt="" id='slideabf2' style={slidesData[currentSlideIndex].style} /></div>
                </div>
                <div id="abf2b2">
                    <div className="boxheadsdesc">{slidesData[currentSlideIndex].desc}</div>
                    <div id="forbtnflex">
                        <div id="f2b2sliderbtnprev" className="f2b2sliderbtn2" onClick={handleprevClick}>&larr;</div>
                        <div id="f2b2sliderbtnnext" className="f2b2sliderbtn2" onClick={handlenextClick}>&rarr;</div>
                    </div>
                </div>
            </div>
        </>
    );
}
