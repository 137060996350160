import React from 'react';
import './About.css';
import './Main.css';



export default function AbFrame1(props) {
    return (
        <>
            <div id="abframe1">
                <div id="forimg" style={{
                    backgroundImage: `url(${props.background})`,
                }}>
                </div>
                <div className="modalcontainer2">
                    <div className="modal2">
                        <div className="boxheads1">{props.head1}</div>
                        <div className="boxheads2">{props.head2}</div>
                        <div className="boxheadsdesc">{props.desc}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
