import React from 'react';
import './WhatsAppButton.css'; // Import the CSS file for styling
import whatsappIcon from './Images/whatsapp.png'; // Replace with your WhatsApp icon path

export default function WhatsAppButton() {
    return (
        <a
            href="https://wa.me/8619333547" // Add your phone number here
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src={whatsappIcon} alt="WhatsApp" className="whatsapp_icon" />
        </a>
    );
}
