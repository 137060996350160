import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Main.css';
import './Frame1.css';

export default function Frame1(props) {
    // useNavigate hook for programmatic navigation
    const navigate = useNavigate();

    // Function to handle button click and navigate
    const handleExploreClick = () => {
        navigate('/Jaisalmer'); // Navigate to the Jaisalmer page
    };

    return (
        <>
            <div id="frame1">
                <div id="fb1">
                    <div id="head1">{props.head1}</div>
                    <div id="head2">{props.head2}</div>
                    {/* Button retains its appearance and navigates like a link */}
                    <button id='expbtn' onClick={handleExploreClick}>
                        Explore
                    </button>
                </div>
                <div id="fb2">
                    <img src={props.src} alt="" id='fb2img' className=''/>
                </div>
            </div>
        </>
    );
}
