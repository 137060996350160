import React, { useState } from 'react';
import Slide1 from './Images/Fort1.jpg';
import Slide2 from './Images/patwa.jpg';
import Slide3 from './Images/gadisar.jpg';
import Slide4 from './Images/museum.jpg';
import Slide5 from './Images/badabagh.jpg';
import Slide6 from './Images/jain.jpg';
import Slide7 from './Images/tanot.jpg';
import Slide8 from './Images/silk1.jpg';
import Slide9 from './Images/tower1.jpg';
import './Main.css';

export default function Frame2() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        { src: Slide1, title: "Majestic Jaisalmer Fort - Sonar Quila", desc:` Jaisalmer Fort, also known as Sonar Quila, is a stunning golden fortress that majestically rises from the desert sands, showcasing exquisite Rajput architecture and rich history.`, className: "slide1" },
        { src: Slide2, title: "Architectural Beauty of Patwon Ki Haveli", desc:`Patwon Ki Haveli is a magnificent collection of intricately carved havelis, renowned for its elaborate architecture and stunning facades that reflect the opulence of Rajasthan's merchant class.`, className: "slide2" },
        { src: Slide3, title: "Gadisar Lake - The Oasis of Jaisalmer", desc:`Gadisar Lake is a serene and picturesque reservoir, offering a tranquil escape with its charming surroundings and historic temples.`, className: "slide3"},
        { src: Slide4, title: "Desert Cultural Museum", desc:`The Desert Cultural Museum showcases the vibrant traditions and rich history of the Thar Desert, offering an insightful journey through the region's cultural tapestry.`, className: "slide4"},
        { src: Slide5, title: "Bada Bagh - Royal Tombs", desc:`Bada Bagh features a series of majestic cenotaphs, offering a serene glimpse into the royal legacy and architectural elegance of Jaisalmer.`, className: "slide5"},
        { src: Slide6, title: "Jain Temple (Lodurva) - Spiritual Serenity", desc:`The Jain Temple in Lodurva is renowned for its peaceful ambiance and intricate architecture, reflecting the spiritual heritage of the region.`, className: "slide6"},
        { src: Slide7, title: "Tanot Mata Temple - Divine Sanctuary", desc:`Tanot Mata Temple is a revered spiritual site, celebrated for its mystical aura and its significance in local legend and devotion.`, className: "slide7"},
        { src: Slide8, title: "Silk Route Art Gallery - Artistic Treasures", desc:`The Silk Route Art Gallery showcases a captivating collection of artworks, reflecting the rich cultural heritage and artistic diversity of the region.`, className: "slide8"},
        { src: Slide9, title: "Tazia Tower - A Unique Architecture", desc:`Tazia Tower stands out with its distinctive architectural style and intricate carvings, offering a fascinating glimpse into Jaisalmer’s cultural and historical heritage.`, className: "slide9"},
    ];

    const handlenextClick = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const handleprevClick = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    return (
        <>
            <div id="frame2">
                <div id="f2b1">
                    <div id="f2b1b1">
                        <div className="boxheads1">Explore the</div>
                        <div className='boxheads2'>{slides[currentSlide].title}</div>
                    </div>
                    <div className="boxheadsdesc">{slides[currentSlide].desc}</div>
                </div>
                <div id="f2b2">
                    <div id="f2b2sliderbtnprev" className="f2b2sliderbtn" onClick={handleprevClick}>&larr;</div>
                    <div id="f2b2slider">
                        <img src={slides[currentSlide].src} alt="" id="slide" className={slides[currentSlide].className} />
                    </div>
                    <div id="f2b2sliderbtnnext" className="f2b2sliderbtn" onClick={handlenextClick}>&rarr;</div>
                </div>
            </div>
        </>
    );
}
