import React from 'react';
import Abframe1 from './Abframe1';
import Ppframe2 from './Ppframe2';
import background from './Images/pk.jpg';

const About = () => {
    return (
        <div>
            <Abframe1 background={background} head1={"Discover"} head2={"Exclusive Desert Adventure Packages"} desc={`Discover the magic of the Thar Desert with our handpicked adventure packages. Whether you're after thrilling activities like camel safaris and quad biking or seeking a serene escape under the stars, we have the perfect package for you. Immerse yourself in the rich culture of Rajasthan with traditional music, dance, and authentic cuisine. Choose your adventure and create unforgettable memories today!`}></Abframe1>
            <Ppframe2/>

        </div>
    );
};

export default About;