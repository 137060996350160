import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirecting
import './Admin.css';

export default function AddReviews() {
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [review, setReview] = useState('');
    const [stars, setStars] = useState(0); // Assuming stars is an integer
    const [image, setImage] = useState(null);
    const [reviews, setReviews] = useState([]); // State to store reviews
    const [editMode, setEditMode] = useState(false); // Track whether editing
    const [editReviewId, setEditReviewId] = useState(null); // Store the ID of the review being edited
    const [isLoggedIn, setIsLoggedIn] = useState(false); // State for session check
    const navigate = useNavigate(); // Use for redirection

    useEffect(() => {
        // Check if the user is logged in by calling the session check API
        fetch('/backend/check_session.php')
            .then((response) => response.json())
            .then((data) => {
                if (!data.loggedIn) {
                    // If not logged in, redirect to login page
                    navigate('/admin');
                } else {
                    setIsLoggedIn(true);
                }
            })
            .catch((error) => console.error('Error checking session:', error));
    }, [navigate]);

    // Function to fetch reviews from the server
    const fetchReviews = async () => {
        try {
            const response = await fetch('/backend/get_reviews.php');
            const data = await response.json();
            
            if (data.success) {
                setReviews(data.reviews);
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error("Error fetching reviews:", error);
        }
    };

    // Fetch reviews when the component loads
    useEffect(() => {
        fetchReviews();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formData = new FormData();
        formData.append('name', name);
        formData.append('location', location);
        formData.append('review', review);
        formData.append('stars', stars);
        if (image) formData.append('image', image);
    
        const url = editMode ? '/backend/update_review.php' : '/backend/add_review.php';
        if (editMode) formData.append('review_id', editReviewId); // Add review ID for editing
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });
    
            const data = await response.json();
            console.log("Server Response:", data);  // Log the server response for debugging
    
            if (data.success) {
                alert(editMode ? "Review updated successfully" : "Review added successfully");
    
                // Optionally reset form fields
                setName('');
                setLocation('');
                setReview('');
                setStars(0);
                setImage(null);
                setEditMode(false); // Exit edit mode
                setEditReviewId(null); // Reset edit ID
    
                // Fetch reviews again to update the table
                fetchReviews();
            } else {
                alert(data.message || "Failed to add/update review");
            }
        } catch (error) {
            console.error("Error adding/updating review:", error);
            alert("An error occurred. Please try again.");
        }
    };
    

    // Handle editing of a review
    const handleEdit = (review) => {
        setName(review.name);
        setLocation(review.location);
        setReview(review.review);
        setStars(review.stars);
        setEditReviewId(review.review_id);
        setEditMode(true);
    };

    // Handle deleting of a review
    const handleDelete = async (review_id) => {
        // Confirmation dialog to ask the user before deleting
        if (window.confirm("Are you sure you want to delete this review?")) {
            try {
                const response = await fetch('/backend/delete_review.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ review_id }),  // Pass the review_id
                });
    
                const data = await response.json();  // Parse the response JSON
                if (data.success) {
                    alert("Review deleted successfully");
                    fetchReviews();  // Refresh the list of reviews after deletion
                } else {
                    alert(data.message || "Failed to delete review");
                }
            } catch (error) {
                console.error("Error deleting review:", error);
                alert("An error occurred. Please try again.");
            }
        } else {
            // If the user cancels the action
            console.log("Delete action cancelled");
        }
    };
    

    return (
        <>
            <div id="dbf1">
                <div id="head2">{editMode ? "Edit" : "Add"} your testimonials here.</div>
                <form onSubmit={handleSubmit}>
                    <div className="grid-container">
                        <div className="grid-item">Customer Name</div>
                        <div className="grid-item">
                            <input
                                type="text"
                                name='name'
                                id='name'
                                className='cctxt'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="grid-item">Customer Location</div>
                        <div className="grid-item">
                            <input
                                type="text"
                                name='location'
                                id='location'
                                className='cctxt'
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                required
                            />
                        </div>
                        <div className="grid-item">Customer Review</div>
                        <div className="grid-item">
                            <textarea
                                name="review"
                                id="review"
                                value={review}
                                onChange={(e) => setReview(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="grid-item">Stars</div>
                        <div className="grid-item">
                            <input
                                type="number"
                                name='stars'
                                id='stars'
                                className='cctxt'
                                min="1"
                                max="5"
                                value={stars}
                                onChange={(e) => setStars(Number(e.target.value))}
                                required
                            />
                        </div>
                        <div className="grid-item">Customer Image</div>
                        <div className="grid-item">
                            <input
                                type="file"
                                name="image"
                                id="image"
                                accept="image/*"
                                onChange={(e) => setImage(e.target.files[0])}
                            />
                        </div>
                        <div className="grid-item">
                            <button type="submit" className="btn btn-primary">{editMode ? "Update Review" : "Add Review"}</button>
                        </div>
                    </div>
                </form>

                {/* Display the reviews in the table */}
                <table id='cctbl'>
                    <thead>
                        <tr className='tr'>
                            <th>Customer Name</th>
                            <th>Customer Location</th>
                            <th>Customer Review</th>
                            <th>Stars</th>
                            <th>Customer Image</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reviews.map((review) => (
                            <tr key={review.review_id} className='tr'>
                                <td>{review.name}</td>
                                <td>{review.location}</td>
                                <td>{review.review}</td>
                                <td>{review.stars}</td>
                                <td>
                                    {review.image ? (
                                        <img src={`/uploads/${review.image}`} alt="Customer" width="50" />
                                    ) : "No image"}
                                </td>
                                <td>
                                    <button onClick={() => handleEdit(review)} className="btn btn-secondary">Edit</button>
                                    <button onClick={() => handleDelete(review.review_id)} className="btn btn-danger">Delete</button>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
