import React from 'react';
import Abframe1 from './Abframe1';
import Ggframe from './ggframe.js';
import background from './Images/gg.jpg';

const About = () => {
    return (
        <div>
            <Abframe1 background={background} head1={"Exploring &"} head2={"Capturing Desert Magic"} desc={" Explore our gallery to experience the breathtaking beauty and vibrant moments of the desert. From stunning landscapes and cultural performances to unforgettable activities, each image tells a story of adventure and enchantment in Jaisalmer. Dive into our collection and let the visuals transport you to the heart of the Thar Desert."}></Abframe1>
            <Ggframe/>

        </div>
    );
};

export default About;