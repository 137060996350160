import React from 'react';
import Abframe1 from './Abframe1';
import Abframe2 from './Abframe2';
import background from './Images/about.jpg';

const About = () => {
  return (
    <div>
      <Abframe1 background={background} head1={"Welcome to"} head2={"Desert Pearl Camp Resort"} desc={"Jaisalmer offers a unique opportunity to experience the sights and sounds of the Thar Desert under a mesmerizing canopy of stars. Our Desert Camp, an eco-friendly lodge, is nestled in the heart of the Thar Sands, 40 km (a 40-minute drive) from Jaisalmer. Surrounded by dunes, the camp provides guests with a sense of complete seclusion and a true escape from the outside world."}></Abframe1>
      <Abframe2></Abframe2>

    </div>
  );
};

export default About;