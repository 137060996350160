import React from 'react';
import Abframe1 from './Abframe1';
import Jframe from './jframe.js';
import background from './Images/expjsm.jpg';

const About = () => {
    return (
        <div>
            <Abframe1 background={background} head1={"Discover the"} head2={"Wonders of Jaisalmer"} desc={`Jaisalmer, often called the "Golden City," is a rich tapestry of history, culture, and natural beauty. Exploring this enchanting city feels like stepping into a fairy tale from a bygone era. The history of Jaisalmer is steeped in charm, much like other cities in Rajasthan, with a proud heritage rooted in Rajputana history. Founded around 1156 AD by Raja Rawal Jaisal, a Bhatti Rajput ruler, the city was established on the advice of a local hermit named Eesaal. Here are some of the must-visit places in Jaisalmer:`}></Abframe1>
            <Jframe/>

        </div>
    );
};

export default About;