import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import './Admin.css';

export default function Upackages() {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [features, setFeatures] = useState('');  // State for features
    const [image, setImage] = useState(null);
    const [packages, setPackages] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editPackageId, setEditPackageId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);  // Track login status
    const navigate = useNavigate(); // For navigation and redirection


    // Session check to restrict non-admin access
    useEffect(() => {
        fetch('/backend/check_session.php')
            .then((response) => response.json())
            .then((data) => {
                if (!data.loggedIn) {
                    navigate('/admin');  // Redirect to login if not logged in
                } else {
                    setIsLoggedIn(true);  // Mark user as logged in
                }
            })
            .catch((error) => console.error('Error checking session:', error));
    }, [navigate]);

    // Fetch packages from the server
    const fetchPackages = async () => {
        try {
            const response = await fetch('/backend/get_packages.php');
            const data = await response.json();
            
            if (data.success) {
                setPackages(data.packages);
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error("Error fetching packages:", error);
        }
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('price', price);
        formData.append('description', description);
        formData.append('features', features);  // Add features to form data
        if (image) formData.append('image', image);

        const url = editMode ? '/backend/update_package.php' : '/backend/add_package.php';
        if (editMode) formData.append('package_id', editPackageId);

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.success) {
                alert(editMode ? "Package updated successfully" : "Package added successfully");

                // Reset form fields
                setName('');
                setPrice('');
                setDescription('');
                setFeatures('');  // Reset features field
                setImage(null);
                setEditMode(false);
                setEditPackageId(null);

                fetchPackages();
            } else {
                alert(data.message || "Failed to add/update package");
            }
        } catch (error) {
            console.error("Error adding/updating package:", error);
            alert("An error occurred. Please try again.");
        }
    };

    // Handle package deletion
    const handleDelete = async (package_id) => {
        if (window.confirm("Are you sure you want to delete this package?")) {
            try {
                const response = await fetch('/backend/delete_package.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ package_id }),
                });

                const data = await response.json();
                if (data.success) {
                    alert("Package deleted successfully");
                    fetchPackages();  // Refresh the list of packages
                } else {
                    alert("Failed to delete package");
                }
            } catch (error) {
                console.error("Error deleting package:", error);
                alert("An error occurred. Please try again.");
            }
        }
    };

    const handleEdit = (packageItem) => {
        setName(packageItem.name);
        setPrice(packageItem.price);
        setDescription(packageItem.description);
        setFeatures(packageItem.features);  // Set features field for editing
        setEditPackageId(packageItem.package_id);
        setEditMode(true);
    };

    return (
        <>
            <div id="dbf1">
                <div id="head2">{editMode ? "Edit" : "Add"} your packages here.</div>
                <form onSubmit={handleSubmit}>
                    <div className="grid-container">
                        <div className="grid-item">Package Name</div>
                        <div className="grid-item">
                            <input
                                type="text"
                                name='name'
                                id='name'
                                className='cctxt'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="grid-item">Package Price</div>
                        <div className="grid-item">
                            <input
                                type="text"
                                name='price'
                                id='price'
                                className='cctxt'
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                required
                            />
                        </div>
                        <div className="grid-item">Package Description</div>
                        <div className="grid-item">
                            <textarea
                                name="description"
                                id="description"
                                className='cctxt'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="grid-item">Package Features (Semicolon-separated)</div>
                        <div className="grid-item">
                            <textarea
                                name="features"
                                id="features"
                                className='cctxt'
                                value={features}
                                onChange={(e) => setFeatures(e.target.value)}
                                placeholder="Enter features separated by semicolons"
                                required
                            ></textarea>
                        </div>
                        <div className="grid-item">Package Image</div>
                        <div className="grid-item">
                            <input
                                type="file"
                                name="image"
                                id="image"
                                accept="image/*"
                                onChange={(e) => setImage(e.target.files[0])}
                            />
                        </div>
                        <div className="grid-item">
                            <button type="submit" className="btn btn-primary">{editMode ? "Update Package" : "Add Package"}</button>
                        </div>
                    </div>
                </form>

                {/* Display the packages in the table */}
                <table id='cctbl'>
                    <thead>
                        <tr className='tr'>
                            <th>Package Name</th>
                            <th>Package Price</th>
                            <th>Package Description</th>
                            <th>Package Features</th>  {/* Show features in table */}
                            <th>Package Image</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {packages.map((packageItem) => (
                            <tr key={packageItem.package_id} className='tr'>
                                <td>{packageItem.name}</td>
                                <td>{packageItem.price}</td>
                                <td>{packageItem.description}</td>
                                <td>
                                    {/* Display features line-by-line */}
                                    {packageItem.features.split(';').map((feature, index) => (
                                        <div key={index}>{feature.trim()}</div>
                                    ))}
                                </td>
                                <td>
                                    {packageItem.image ? (
                                        <img src={`/backend/uploads/${packageItem.image}`} alt="Package" width="50" />
                                    ) : "No image"}
                                </td>
                                <td>
                                    <button onClick={() => handleEdit(packageItem)} className="btn btn-secondary">Edit</button>
                                    <button onClick={() => handleDelete(packageItem.package_id)} className="btn btn-danger">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
