import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './Admin.css';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    // Check if the admin is already logged in
    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch('/backend/check_session.php');
                const data = await response.json();

                if (data.loggedIn) {
                    navigate("/dashboard");
                }
            } catch (error) {
                console.error("Error checking session:", error);
            }
        };

        checkSession();
    }, [navigate]);

    const login = async () => {
        if (email === "" || password === "") {
            alert("Please fill all fields.");
        } else {
            try {
                const response = await fetch('/backend/login.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                });

                const data = await response.json();
                if (data.success) {
                    alert("Login Successful.");
                    navigate("/dashboard");
                } else {
                    alert(data.message || "Invalid Email or Password.");
                }
            } catch (error) {
                console.error("Error during login:", error);
                alert("An error occurred. Please try again.");
            }
        }
    };

    return (
        <>
            <div id="login">
                <div id="logincontainer">
                    <div id="loginheader">Login Now</div>
                    <div id="loginform">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className='fields'
                            placeholder='Email-ID'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            name="pass"
                            id="pass"
                            className='fields'
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div id="lf">
                            <button type="submit" id='loginbtn' onClick={login}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
