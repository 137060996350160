import React, { useEffect, useState } from 'react';
import './Main.css';
import './ggframe.css';

export default function Ggframe() {
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        // Fetch the images from the server
        fetch('/backend/get_images.php')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setSlides(data.images); // Set the image list in the state
                } else {
                    console.error('Failed to load images');
                }
            })
            .catch(error => console.error('Error fetching images:', error));
    }, []);

    return (
        <div id="ggframe2">
            <div id="ggframe">
                {slides.map((slide, index) => (
                    <div key={index} className="card">
                        <img
                            src={`/backend/gallery/${slide}`} // Point to the gallery folder
                            alt={`Slide ${index + 1}`}
                            className="card-image"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
