import React from 'react';
import Abframe1 from './Abframe1';
import background from './Images/cc.jpg';
import { Frame4 as Cframe1 } from './Cframe1';

const About = () => {
    return (
        <div>
            <Abframe1 background={background} head1={""} head2={"Get in Touch"} desc={`Have questions or need assistance? Our team is ready to help you plan your perfect desert adventure. Reach out to us and we’ll make sure you have an unforgettable experience.`}></Abframe1>
            <Cframe1 />

        </div>
    );
};

export default About;