import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import fb from './Images/fb.png';
import insta from './Images/insta.png';
import google from './Images/google.png';
import email from './Images/email.png';
import city from './Images/city.png';
import phone from './Images/phone.png';
import location from './Images/location.png';
import award from './Images/award.jpg'; // Import the new award image

export default function Footer() {
    // State to track window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Effect to handle window resizing
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Dynamic styles for responsiveness
    const responsiveStyles = {
        footer: {
            display: 'flex',
            backgroundColor: 'rgb(235, 234, 234)',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
            padding: '2%'
        },
        f11: {
            display: 'flex',
            flexDirection: windowWidth <= 740 ? 'column' : 'row', // Stack items vertically for small screens
            width: windowWidth <= 412 ? '95vw' : '90vw',
            padding: '2%',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
        f1: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            margin: windowWidth <= 740 ? '10px 0' : '0',
        },
        rowf: {
            width: windowWidth <= 638 ? '90vw' : '25vw', // Full width for very small screens
            display: 'flex',
            alignItems: 'center',
            justifyContent: windowWidth <= 638 ? 'center' : 'flex-start',
            marginBottom: windowWidth <= 638 ? '10px' : '0',
        },
        socialLinks: {
            margin: windowWidth <= 638 ? '2%' : '4%',
        },
        socialLinksImg: {
            height: windowWidth <= 740 ? '4vh' : '5vh', // Smaller height for small screens
        },
        sstxt: {
            width: windowWidth <= 638 ? '80vw' : '20vw',
            margin: windowWidth <= 638 ? '0 2%' : '2%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-around',
        },
        sstxt1: {
            fontSize: windowWidth <= 740 ? '15px' : '17px',
            fontFamily: `"Lato", sans-serif !important`,
            color: 'rgb(70, 70, 70)',
        },
        sstxt2: {
            fontSize: windowWidth <= 740 ? '18px' : '20px',
            fontFamily: `"Noto Serif JP", serif !important`,
        },
        f3: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0',
        },
        awardImage: {
            width: windowWidth <= 638 ? '150px' : '200px',
            height: 'auto',
            borderRadius: '5px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        f2: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
        iframe: {
            width: windowWidth <= 740 ? '80vw' : '40vw',
            height: '50vh',
            borderRadius: '10px',
        },
        f22: {
            display: 'flex',
            width: '90vw',
            height: '15vh',
            flexDirection: 'column',
            padding: '2%',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
    };

    return (
        <>
            <div style={responsiveStyles.footer}>
                <div style={responsiveStyles.f11}>
                    <div style={responsiveStyles.f1}>
                        <div style={responsiveStyles.rowf}>
                            <a href="https://www.facebook.com/share/zQxMsHgz77EEdGoN/?mibextid=LQQJ4dj" target="_blank" rel="noopener noreferrer" style={responsiveStyles.socialLinks}>
                                <img src={fb} alt="Facebook" style={responsiveStyles.socialLinksImg} />
                            </a>
                            <a href="https://www.instagram.com/desertpearl_jaisalmer?igsh=MXhsZWJiMDg2ZWo5bQ%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer" style={responsiveStyles.socialLinks}>
                                <img src={insta} alt="Instagram" style={responsiveStyles.socialLinksImg} />
                            </a>
                            <a href="https://g.co/kgs/SnJVvv" target="_blank" rel="noopener noreferrer" style={responsiveStyles.socialLinks}>
                                <img src={google} alt="Google" style={responsiveStyles.socialLinksImg} />
                            </a>
                            <a href="mailto:desertpearlresort@gmail.com" target="_blank" rel="noopener noreferrer" style={responsiveStyles.socialLinks}>
                                <img src={email} alt="Email" style={responsiveStyles.socialLinksImg} />
                            </a>
                        </div>

                        <div style={responsiveStyles.rowf}>
                            <img src={city} alt="" style={responsiveStyles.socialLinksImg} />
                            <div style={responsiveStyles.sstxt}>
                                <div style={responsiveStyles.sstxt1}>City</div>
                                <div style={responsiveStyles.sstxt2}>Jaisalmer</div>
                            </div>
                        </div>

                        <div style={responsiveStyles.rowf}>
                            <a href="tel:+918619333547">
                                <img src={phone} alt="Call" style={responsiveStyles.socialLinksImg} />
                            </a>
                            <div style={responsiveStyles.sstxt}>
                                <div style={responsiveStyles.sstxt1}>Phone Number</div>
                                <div style={responsiveStyles.sstxt2}>
                                    <a href="tel:+918619333547" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91 8619333547
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div style={responsiveStyles.rowf}>
                            <img src={location} alt="" style={responsiveStyles.socialLinksImg} />
                            <div style={responsiveStyles.sstxt}>
                                <div style={responsiveStyles.sstxt1}>Location</div>
                                <div style={responsiveStyles.sstxt2}>Sam Sand Dunes, Jaisalmer</div>
                            </div>
                        </div>
                    </div>

                    <div style={responsiveStyles.f3}>
                        <img src={award} alt="TripAdvisor Award" style={responsiveStyles.awardImage} />
                    </div>

                    <div style={responsiveStyles.f2}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.8774505706515!2d70.54076247522188!3d26.84384987668821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3947bc258f1f1b3b%3A0x9c690201e406e12b!2sDesert%20Pearl%20Camp%20%26%20Resort!5e0!3m2!1sen!2sin!4v1726810068972!5m2!1sen!2sin" style={responsiveStyles.iframe} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

                <div style={responsiveStyles.f22}>
                    <div>© Desert Pearl Camp Resort 2023. Developed By Zenith</div>
                    <div>Cancellation & Refund | Privacy Policy | Terms & Conditions</div>
                </div>
            </div>
        </>
    );
}
